import React, { Component } from 'react'
import ProductsListing from './ProductsListing';
import Fuse from 'fuse.js';
export default class Navbar extends Component {
  state = {
    currentSearch: "",
    products: require('../data/products.json'),
    shownProducts: []
  }

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(e);

    if (this.state.currentSearch === "") {
      this.setState({
        shownProducts: this.state.products
      });
      return;
    }
    const options = {
      keys: ['title', 'company', 'price']
    };
    const fuse = new Fuse(this.state.products, options);

    const results = fuse.search(this.state.currentSearch);
    console.log(results);

    this.setState({
      shownProducts: results
    })
  }

  handleChange = (e) => {
    this.setState({
      currentSearch: e.target.value
    });
  }

  componentDidMount = () => {
    this.setState({
      shownProducts: this.state.products
    })
  }
  

  render() {
    const products = this.state.shownProducts;
    //console.log(this.state.products);
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <a className="navbar-brand" href="http://eventron.ca">Eventron</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarColor02">
            <form className="form-inline my-2 my-lg-12 col-md-12" onSubmit={this.handleSubmit}>
              <input className="form-control mr-sm-2 col-md-10" type="text" placeholder="Search" onChange={this.handleChange} value={this.state.currentSearch} />
              <button className="btn btn-secondary my-2 my-sm-0 col-md-1" type="submit">Search</button>
            </form>
          </div>
        </nav>
        <div>
          <ProductsListing products={products} />  
        </div>        
      </div>
    )
  }
}