import React, { Component } from 'react'

export default class ProductsListing extends Component {
  
  render() {
    console.log(this.props.products);
    const products = this.props.products.map(p => {
      return (
        <div className="card m-3" key={p.id} style={{maxWidth: "20rem"}}>
          <div className="card-body">
            <h6 className="card-header">
              <a className="card-title" href={p.link} target="_blank" rel="noopener noreferrer">{p.title}</a>
            </h6>
            
            <img src={p.imageUrl} alt={p.title} style={{maxWidth: "100%"}} />
            <div className="card-footer">
              <p style={{textAlign: "right"}}>{p.price}$</p>
            </div>
          </div>
        </div>
      )
    })
    return (
      <div>
        <div className="row">
          {products}
        </div>
      </div>
    )
  }
}
